/**
 * Small functions that are needed more than one place
 */
 import React from 'react';

//Takes the literal tag of [1-5]star and replaces it with actual stars
  export function starReplace(number) {
    return "⭐".repeat(number.charAt(0));
  }

//Gives me a heatmap kinda look for the course/tag results PAGES
  export function heatMapColorforValue(value,color) {
    if (color === undefined) {
      color = "170, 170, 170"
    }

    let x = 0.05 + (.1*value);
    if ( value <= 4 ) {
      return {
        "backgroundColor": "rgba(" + color + "," + x + ")"
      }
    } else {
      return {
        "backgroundColor": "rgba(" + color + ",.55)"
      }
    }
  }

  export function Pagi(props) {
    //var totalPages = Math.ceil(props.data.length / props.itemspp)
    var itemsperpage = props.itemspp;
    var totalPages = props.totalP
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
         items.push(
          <button
          onClick={() => props.setP(number)}
          key={number}
         >
           {number}
         </button>
        );
    }
    const handleChange = (event) => {
      props.setPerPage(event.target.value)
      props.setP(1);
    };
    return (
        <div className="pagination">
          <div>
          {props.currentP !== 1 && <button onClick={() => { return props.setP(props.currentP - 1)}}>&laquo; Previous</button>}
            {items}
          {props.currentP !== totalPages && <button onClick={() => { return props.setP(props.currentP + 1)}}>Next &raquo;</button>}
              <label>
                <select value={itemsperpage} onChange={handleChange}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="100">100</option>
                </select>
              </label>
          </div>
        </div>
    )
  }