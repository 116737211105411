import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { starReplace } from "../components/functions"

const Tags = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } ${/[1-5]star$/.test(tag) ? "rated " + starReplace(tag) : "tagged \"" + tag + "\""}`
  var duplicateCourses = [];
  edges.map(({ node }) => {
    const { title } = node.frontmatter
    if (!duplicateCourses[title]) {duplicateCourses[title] = 1} else { duplicateCourses[title]++ };
    return null
  })

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={tagHeader}
        description={tagHeader}
      />
    <article>
      <h2>{tagHeader}</h2>
      <ul className="tag-template-results">
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title } = node.frontmatter
          const { description } = node.frontmatter
          const { date } = node.frontmatter
          return (
            <li key={slug}>
              <Link to={slug}>
              <div className="flexcontainer"> 
                {title}
                { duplicateCourses[title] > 1 && description ? <span className="description">({description})</span> : null}
                <span className="details">{date}</span>
              </div>
              </Link>
            </li>
          )
        })}
      </ul>
      {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
      {/*<Link to="/tags">All tags</Link>*/}
      <hr />
    </article>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`
